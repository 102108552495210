import React from 'react';

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';
import './styles/App.scss';

import { Header } from './components/Header';
import { Main } from './components/Main';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="App animate__animated">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
