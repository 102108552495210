import React from 'react';
import logo from './../assets/logo-desktop.png';
import { Outlet, Link } from "react-router-dom";
import BG from "./../assets/bg-logo.jpg";
import $ from 'jquery';

export const Header = () => {
  const headerNavEvent = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 900) {
      let windowPosition = window.scrollY;
      if (windowPosition && windowPosition > 100) {
        //  $("#headerNavDesktop").fadeIn();
      }
      if (windowPosition && windowPosition <= 100) {
        //  $("#headerNavDesktop").fadeOut();
      }
    }
    $(document).ready(function() {
      $("#btn-open-mobile-menu").off("click");
      $("#btn-open-mobile-menu").on("click", function() {
        if ($("#btn-open-mobile-menu").hasClass("open")) {
          $("#btn-open-mobile-menu").removeClass("open");
          $("nav.header-nav").css({
            "opacity": 0,
            "max-height": 0,
          });
        } else {
          $("#btn-open-mobile-menu").addClass("open");
          $("nav.header-nav").css({
            "opacity": 1,
            "max-height": "200px",
          });
        }
      })
    })
  }
  React.useEffect(() => {
    headerNavEvent();
    return () => {
    }
  }, [headerNavEvent])
  return (
    <header className="site-header">
      <div className="container">
        <div className="site-header-inner">
          <div className="brand">
            <h1 className="margin-0"><a href="/"><img src={logo} width="128" height="128" alt="Open" /></a></h1>
          </div>
          <button className="header-nav-toggle">
            <span className="screen-reader">Menu</span>
            <span className="hamburger">
              <span id="btn-open-mobile-menu" className="hamburger-inner">
              </span>
            </span>
          </button>
          <nav className="header-nav">
            <div className="header-nav-inner">
              <ul className="list-reset text-xs header-nav-right">
                <li>
                  <a href="/#about-section">About</a>
                </li>
                <li>
                  <a href="/#project-section">Things We Do</a>
                </li>
                <li>
                  <a href="/#workflow-section">Our Work</a>
                </li>
                {/* <li>
                  <a href="/#testimonial-section">Testimonial</a>
                </li> */}
              </ul>
              <ul className="list-reset header-nav-right"><li></li></ul>
            </div>
          </nav>
        </div>
      </div>
    </header>

  );
};
