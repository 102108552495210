import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Fade } from "react-awesome-reveal";

export const HeroSection = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <section id="about-section" className="hero section center-content illustration-section-01">
        <div className="container-sm">
          <div className="hero-inner section-inner">
            <div className="hero-content">
              <Fade triggerOnce direction="up">
                <h1 className="mt-0 mb-16 reveal-from-bottom is-revealed" data-reveal-delay="200">
                  We, <span className="text-color-primary">Alter Horizons</span>, are a Web3 focused tech company.
                </h1>
              </Fade>
              <div className="container-xs">
                <Fade triggerOnce direction="up">
                  <p className="margin-0 mb-32 reveal-from-bottom is-revealed" data-reveal-delay="400">
                    We help with NFT launches, build dApps & games and build weird things on the Metaverse.
                  </p>
                </Fade>
                {
                  /*

                  <Fade triggerOnce direction="up">
                    <div className="reveal-from-bottom is-revealed" data-reveal-delay="600">
                      <div className="button-group">
                        <a href="https://cruip.com/" className="button button-primary button-wide-mobile">Get started</a>
                        <a href="https://github.com/cruip/open-react-template/" className="button button-dark button-wide-mobile">View on Github</a>
                      </div>
                    </div>
                  </Fade>
                  */
                }
              </div>
            </div>

            {/* <Fade triggerOnce direction="up">
              <div className="hero-figure reveal-from-bottom illustration-element-01 is-revealed" data-reveal-value="20px" data-reveal-delay="800">
                <a onClick={handleShow} data-video="https://player.vimeo.com/video/174002812" href="#0" aria-controls="video-modal">
                  <img className="has-shadow" src="https://open.cruip.com/static/media/video-placeholder.a622fc5d.jpg" width="896" height="504" alt="Hero" />
                </a>
              </div>
            </Fade> */}
          </div>
        </div>
      </section>
      <Modal show={modalShow} onHide={handleClose}>
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/rokGy0huYEA`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </Modal>
    </>
  );
};
