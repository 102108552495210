import React from 'react';
import {HeroSection} from './main/HeroSection';
import {TilesSection} from './main/TilesSection';
import {SplitSection} from './main/SplitSection';
import {TestimonialSection} from './main/TestimonialSection';
import {CtaSection} from './main/CtaSection';

import $ from 'jquery';

export const Main = () => {

  return (

    <main  className="site-content" >
      <HeroSection />
      <TilesSection />
      <SplitSection />
      {/* <TestimonialSection />  */}
    </main>

  );
};
