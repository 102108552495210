import React from 'react';
import logo from './../assets/logo.png';
import $ from 'jquery';
import BG from "./../assets/bg.gif";

export const Footer = () => {
  return (
    <footer className="site-footer center-content-mobile">
      <div className="container">
        <div className="site-footer-inner">
          <div className="footer-top space-between text-xxs">
            <div className="brand">
              <h1 className="m-0">
                <a href="/">
                  <img src={logo} width="128" height="128" alt="Open" />
                </a>
              </h1>
            </div>
            <div className="footer-social">
              <ul className="list-reset">
                {
                  /*<!--
                    <li>
                      <a href="https://facebook.com/">
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <title>Facebook</title>
                          <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"></path>
                        </svg>
                      </a>
                    </li>
                    -->
                    */
                }
                <li>
                  <a href="mailto:contact@alterhorizons.com">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-youtube" viewBox="0 0 16 16">
                      <title>Contact US</title>
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                    </svg>
                  </a>
                </li> 
                <li>
                  <a href="https://www.youtube.com/channel/UCdSKjm3BMEWn_XkaI2prWWA">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-youtube" viewBox="0 0 16 16">
                      <title>Youtube</title>
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/alter_horizons">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <title>Twitter</title>
                      <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/alter_horizons/">
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <title>Instagram</title>
                      <g>
                        <circle cx="12.145" cy="3.892" r="1"></circle>
                        <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"></path>
                        <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"></path>
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <nav className="footer-nav">
              <ul className="list-reset">
                <li>
                  <a href="/#about-section">About</a>
                </li>
                <li>
                  <a href="/#project-section">Things We Do</a>
                </li>
                <li>
                  <a href="/#workflow-section">Our Work</a>
                </li>
                {/* <li>
                  <a href="/#testimonial-section">Testimonial</a>
                </li> */}
              </ul>
            </nav>
            <div className="footer-copyright">Made by Alter Horizons. All right reserved </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
