import React from 'react';
import { Fade } from "react-awesome-reveal";

export const TilesSection = () => {
  return (
    <section id="project-section" className="features-tiles section">
      <div className="container">
        <div className="features-tiles-inner section-inner pt-0">
          <div className="section-header center-content">
            <div className="container-xs">
              <h2 className="mt-0 mb-16">Things We Do</h2>
              <p className="margin-0"></p>
            </div>
          </div>
          <div className="tiles-wrap center-content">

            <Fade triggerOnce direction="up">
              <div className="tiles-item reveal-from-bottom is-revealed">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img src="https://open.cruip.com/static/media/feature-tile-icon-01.0f9928d7.svg" width="64" height="64" alt="Features tile icon 01" />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Launch NFTs</h4>
                    <p className="margin-0 text-sm">
                      We have experience launching NFTs on Ethereum (ETH), Polygon (MATIC) and Solana (SOL)
                  </p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="up" delay={200}>
              <div className="tiles-item reveal-from-bottom is-revealed" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img src="https://open.cruip.com/static/media/feature-tile-icon-02.bd39f304.svg" width="64" height="64" alt="Features tile icon 02" />
                    </div>
                  </div
                  ><div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Build dApps</h4>
                    <p className="m-0 text-sm">
                      We have worked on a variety of different dApps to add value to NFT projects.
                  </p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="up" delay={400}>
              <div className="tiles-item reveal-from-bottom is-revealed" data-reveal-delay="400">
                <div className="tiles-item-inner"><div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="https://open.cruip.com/static/media/feature-tile-icon-03.66f37ba5.svg" width="64" height="64" alt="Features tile icon 03" />
                  </div>
                </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Manage Community</h4>
                    <p className="margin-0 text-sm">
                      We have a team of experienced community managers to assist in managing your community.
                  </p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="up">
              <div className="tiles-item reveal-from-bottom is-revealed">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img src="https://open.cruip.com/static/media/feature-tile-icon-04.836acd10.svg" width="64" height="64" alt="Features tile icon 04" />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Games</h4>
                    <p className="m-0 text-sm">
                      We can build games using NFTs and also within the Metaverse (such as Sandbox).
                  </p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="up" delay={200}>
              <div className="tiles-item reveal-from-bottom is-revealed" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img src="https://open.cruip.com/static/media/feature-tile-icon-05.fa9ba00b.svg" width="64" height="64" alt="Features tile icon 05" />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Create Artwork</h4>
                    <p className="m-0 text-sm">
                    We have a team of artists that can create artwork in different styles such as Voxel, Pixel & Anime. 
                  </p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="up" delay={400}>
              <div className="tiles-item reveal-from-bottom is-revealed" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img src="https://open.cruip.com/static/media/feature-tile-icon-06.6a177696.svg" width="64" height="64" alt="Features tile icon 06" />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Marketing Plan</h4>
                    <p className="m-0 text-sm">
                      If you want, we can even come up with a fullsuite marketing plan to turn your idea into reality.
                  </p>
                  </div>
                </div>
              </div>

            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};
