import React from 'react';
import MetavadersProject from './../../assets/projects/metavaders.png';
import LilbobProject from './../../assets/projects/lilbob.png';
import FishballRepublicProject from './../../assets/projects/fishballrepublic.png';
import VideoPlayer from "./../../helpers/video";
import { Fade } from "react-awesome-reveal";

interface VideoSource {
  src: string,
  type: string
}

const vFBROptions = {
  sources: [
    {
      autoplay: false,
      controls: true,
      src: "/assets/fbr_video.mp4",
      type: "video/mp4"
    }
  ]
};

const vLilBobOptions = {
  sources: [
    {
      autoplay: false,
      controls: true,
      src: "/assets/LilBob.mp4",
      type: "video/mp4"
    }
  ]
};

export const SplitSection = () => {
  return (
    <section id="workflow-section" className="features-split section illustration-section-02">
      <div className="container">
        <div className="features-split-inner section-inner has-top-divider">
          <div className="section-header center-content">
            <div className="container-xs">
              <h2 className="mt-0 mb-16">Work That We've Done</h2>
              <p className="margin-0">Just example of some work we've done in the past. If you want more examples just let us know!</p>
            </div>
          </div>
          <div className="split-wrap invert-mobile">
            <Fade triggerOnce direction="left">
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left is-revealed" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">METAVADERS</div>
                  <h3 className="mt-0 mb-12"><a href="https://metavaders.io"><u>NFT Launch + dApp Support</u></a></h3>
                  <p className="margin-0">
                    We helped the Metavaders project launch their 10,101 collection of NFT on ETH as well as their subsequent NFT airdrop on MATIC. There was a variety of custom functions as well as dApps built for this project. You can try out some of the games here: <br/>
                    <a href="https://app.metavaders.io/games"><u>Metavaders Games</u></a>
                </p>
                </div>
                <div className="split-item-image center-content-mobile reveal-from-bottom split-item-image-fill is-revealed" data-reveal-container=".split-item">
                    <div className="yt-wrapper">
                      <iframe
                        width="528"
                        height="396"
                        src={`https://www.youtube.com/embed/RRlO80YkJ_U`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="right" delay={100}>
              <div className="split-item on-right">
                <div className="split-item-image center-content-mobile reveal-from-bottom split-item-image-fill" data-reveal-container=".split-item">
                    <div className="yt-wrapper">
                      <iframe
                        width="528"
                        height="396"
                        src={`https://www.youtube.com/embed/PSiOPUuuQoQ`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                </div>
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">Lil BOB</div>
                  <h3 className="mt-0 mb-12"><a href="https://lilbobnft.nohyp.com"><u>Lil BOB NFT</u></a></h3>
                  <p className="margin-0">
                    Lil BOB (ze/zir) is a collection of 1,111 unique NFTs living on the Ethereum Blockchain. The project was created by the brand 'No.HYP' where we provided tech support.
                </p>
                </div>
              </div>
            </Fade>
            <Fade triggerOnce direction="left"  delay={200}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">FISHBALL REPUBLIC</div>
                  <h3 className="mt-0 mb-12"><a href="https://fishballrepublic.io"><u>Fishball Republic</u></a></h3>
                  <p className="margin-0">
                    Fishball Republic is a collection of 5,555 unique NFTs living on the Solana Blockchain. The NFT project is a collobration of local Hong Kong businesses YuDanDon, deHub & 谷live. We were involved in the website, NFT setup and mint page.
                </p>
                </div>
                <div className="split-item-image center-content-mobile reveal-from-bottom split-item-image-fill" data-reveal-container=".split-item">
                    <div className="yt-wrapper">
                      <iframe
                        width="528"
                        height="396"
                        src={`https://www.youtube.com/embed/0wIRIhgFlTc`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};
